import React, { useEffect, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Cherryblossom } from 'react-cherryblossom';
import { useHistory } from 'react-router-dom';
import { clearTokens, RegisterWithInfo } from '../Utils/auth';
import ThumbnailImage from '../images/img.jpg';
import { toast } from 'react-hot-toast';

const Login: React.FC = () => {
    const [input1,setInput1] = useState("");
    const [input2,setInput2] = useState("");
    const [input3,setInput3] = useState("");
    const [input4,setInput4] = useState("");


    useEffect(() => clearTokens(), []);
    const history = useHistory();

    const register = async () => {
      if ( input1=='' || input2=='' ) {toast.error("아이디와 비밀번호를 올바르게 입력해주세요.")}
      else{
        if (
          await RegisterWithInfo({
            username:input1,
            password:input2,
            pwdchk:input3,
            discord:input4,
          })
        ) {
          history.push('/');
        }
      }
    };

    const enterToSubmit = useCallback(
        (e) => {
          if (e.key === 'Enter') register();
        },
        [register],
    );

    return (
        <Wrapper>
          <LoginFormContainer>
            <TitleContainer>
              <Emoji>🔐<br/></Emoji>
              <Name>MARUCHE에<br/></Name>
              <Title>회원가입하기</Title>
            </TitleContainer>
            <InputContainer>
              <RegisterInput type="text" value={input1} onChange={e => setInput1(e.target.value)} placeholder="아이디"/>
              <RegisterInput type="password" value={input2} onChange={e => setInput2(e.target.value)} placeholder="비밀번호"/>
              <RegisterInput type="password" value={input3} onChange={e => setInput3(e.target.value)} placeholder="비밀번호 확인"/>
              <RegisterInput type="text" value={input4} onChange={e => setInput4(e.target.value)} placeholder="디스코드 태그 (선택사항)"/>
              <RegusterButton onClick={register}>회원가입</RegusterButton>
              <LoginText>이미 계정이 있으시다면: </LoginText><LoginLink>로그인</LoginLink>
           </InputContainer>
          </LoginFormContainer>
          <RightImage/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 540px;
  place-items: center;
  background-color: #fff;
`;
const LoginFormContainer = styled.div`
  display: grid;
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  height: auto;
  place-items: center;
  @media screen and (max-width: 1100px){
    grid-column: 1/3;
  }
`;
const TitleContainer = styled.div`
  width: 400px;
  margin-bottom: 25px;
`
const Emoji = styled.span`
  font-family: 'EMOJI';
  font-size: 36px;
`
const Name = styled.span`
  font-size: 36px;
  font-weight: 800;
  color: #689BC4;
  background: linear-gradient(to right, #5C72BC, #9DB1F4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const Title = styled.span`
  font-size: 36px;
  font-weight: 500;
`;
const InputContainer = styled.div``;
const RegusterButton = styled.div`
  display: flex;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 18px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 17px;
  background: linear-gradient(to right, #5C72BC, #9DB1F4);
  color: #fff;
`;
const RegisterInput = styled.input`
  -webkit-appearance: none;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 24px;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0px 0px 0px 1px #eeeeee;
  background-color: #fff;
  font-size: 17px;
  transition: 300ms;
  &:hover {
    box-shadow: inset 0px 0px 0px 2px #d1d1d1;
  }
  &:focus {
    outline: 0;
    box-shadow: inset 0px 0px 0px 2px #3182f6;
  }
  &::placeholder {
    color: #d1d1d1;
  }
`;
const LoginLink = styled.a`
  font-size: 16px;
  cursor: pointer;
  color: #3182f6;
`;
const LoginText = styled.span`
  font-size: 15px;
  color: #d1d1d1;
`;
const RightImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("https://wallpapercave.com/wp/wp4083696.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1100px){
    display: none;
  }
`

export default Login;
