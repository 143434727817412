import { cookie } from './cookie'
import { User } from '../Interfaces/User'
import { getAccessToken } from './auth';
import { api } from './api';

export const saveMyData = async (myData: User) => {
    cookie.set("USER_INFO", myData);
};

export const getMyLocalData = () =>{
    return cookie.get("USER_INFO") as User | undefined;
};

export const fetchMyData = async (token?: string) => {
    if (!token && !getAccessToken()) {
      throw new Error('No Auth Data');
    }
    const myData = await api('/user/me', {})
    await saveMyData(myData);
    return myData;
};
  
export const getMyData = () => {
    const cached = getMyLocalData();
    if (cached) return Promise.resolve(cached);
    else return fetchMyData();
};
  