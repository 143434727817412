import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Router from './routes/Routes';
import './fonts.css'

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false}/>
      <Router/>
    </>
  );
}

export default App;
