import React, { useEffect, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Cherryblossom } from 'react-cherryblossom';
import { useHistory } from 'react-router-dom';
import { clearTokens, loginWithInfo } from '../Utils/auth';
import ThumbnailImage from '../images/img.jpg';
import { toast } from 'react-hot-toast';

const Login: React.FC = () => {
    const [input1,setInput1] = useState("");
    const [input2,setInput2] = useState("");

    useEffect(() => clearTokens(), []);

    const history = useHistory();

    const login = async () => {
      if ( input1=='' || input2=='' ) {toast.error("아이디와 비밀번호를 올바르게 입력해주세요.")}
      else{
        if (
          await loginWithInfo({
            username:input1,
            password:input2,
          })
        ) {
          history.push('/');
        }
      }
    };

    const enterToSubmit = useCallback(
        (e) => {
          if (e.key === 'Enter') login();
        },
        [login],
    );

    return (
        <Wrapper>
          <LoginFormContainer>
            <TitleContainer>
              <Emoji>🔐<br/></Emoji>
              <Name>MARUCHE에<br/></Name>
              <Title>로그인하기</Title>
            </TitleContainer>
            <InputContainer>
              <LoginInput type="text" value={input1} onChange={e => setInput1(e.target.value)} placeholder="아이디"/>
              <LoginInput type="password" value={input2} onChange={e => setInput2(e.target.value)} placeholder="비밀번호"/>
              <LoginButton onClick={login}>로그인</LoginButton>
              <RegisterText>아직 회원이 아니신가요? </RegisterText><RegisterLink href="/auth/register">회원가입</RegisterLink>
           </InputContainer>
          </LoginFormContainer>
          <RightImage/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 540px;
  place-items: center;
  background-color: #fff;
`;
const LoginFormContainer = styled.div`
  display: grid;
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  height: auto;
  place-items: center;
  @media screen and (max-width: 1100px){
    grid-column: 1/3;
  }
`;
const TitleContainer = styled.div`
  width: 400px;
  margin-bottom: 25px;
`
const Emoji = styled.span`
  font-family: 'EMOJI';
  font-size: 36px;
`
const Name = styled.span`
  font-size: 36px;
  font-weight: 800;
  color: #689BC4;
  background-size: 200% auto;
  background-image: linear-gradient(to right, #5C72BC 0%, #9DB1F4  51%, #5C72BC  100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s;
    &:hover {
      background-position: right center;
    }
`;
const Title = styled.span`
  font-size: 36px;
  font-weight: 500;
`;
const InputContainer = styled.div``;
const LoginButton = styled.div`
  display: flex;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 18px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 17px;
  background-size: 200% auto;
  transition: all 800ms cubic-bezier(0.600, 0.000, 0.190, 1.010);
  transition-timing-function: cubic-bezier(0.600, 0.000, 0.190, 1.010);
  background-image: linear-gradient(to right, #5C72BC 0%, #9DB1F4  51%, #5C72BC  100%);
  color: #fff;
    &:hover {
      background-position: right center;
    }
`;
const LoginInput = styled.input`
  -webkit-appearance: none;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 24px;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0px 0px 0px 1px #eeeeee;
  background-color: #fff;
  font-size: 17px;
  transition: 300ms;
  &:hover {
    box-shadow: inset 0px 0px 0px 2px #d1d1d1;
  }
  &:focus {
    outline: 0;
    box-shadow: inset 0px 0px 0px 2px #5C72BC;
  }
  &::placeholder {
    color: #d1d1d1;
  }
`;
const RegisterLink = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: #5C72BC;
  text-decoration: none;
`;
const RegisterText = styled.span`
  font-size: 15px;
  color: #d1d1d1;
`;
const RightImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("https://wallpapercave.com/wp/wp4083696.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1100px){
    display: none;
  }
`

export default Login;
