import { cookie } from './cookie'
import { AuthTokens } from '../Interfaces/AuthTokens'
import toast, { Toaster } from 'react-hot-toast';
import { LoginInfo, RegisterInfo } from '../Interfaces/AuthInfo';
import { api } from './api';
import { fetchMyData } from './user';
import { Redirect } from 'react-router-dom';

export const getAccessToken = (): string | undefined => {
    return cookie.get("ACCESS_TOKEN");
};
  
export const getRefreshToken = (): string | undefined => {
    return cookie.get("REFRESH_TOKEN");
};
  
export const setTokens = (tokens: AuthTokens) => {
    cookie.set("ACCESS_TOKEN", tokens.accessToken);
    cookie.set("REFRESH_TOKEN", tokens.refreshToken);
};
  
export const clearTokens = () => {
    cookie.remove("ACCESS_TOKEN");
    cookie.remove("REFRESH_TOKEN");
    cookie.remove("USER_INFO");
};
  
export const clearUserInfo = () => {
    cookie.remove("USER_INFO");
};

export const loginWithInfo = async ({
    username,
    password,
  }:LoginInfo) => {
    try {
      const authtoast = toast.loading('인증 서버와 통신중...');
      const res = await api('/auth/login', {
        password,
        username,
      });
      if(res.status){
        setTokens(res.token);
        await fetchMyData();
        toast.success('로그인 성공', {
          id: authtoast,
        });
        return true;
      }
      else{
        toast.error('로그인 실패', {
          id: authtoast,
        });
        return false;
      }
    } catch (e) {
      return false;
    }
};

export const RegisterWithInfo = async ({
  username,
  password,
  pwdchk,
  discord,
}:RegisterInfo) => {
  try {
    const authtoast = toast.loading('인증 서버와 통신중...');
    const res = await api('/auth/register', {
      password,
      username,
      pwdchk,
      discord,
    });
    if(res.status){
      setTokens(res.token);
      await fetchMyData();
      toast.success('회원가입 성공', {
        id: authtoast,
      });
      return true;
    }
    else{
      toast.error('회원가입 실패', {
        id: authtoast,
      });
      return false;
    }
  } catch (e) {
    return false;
  }
};