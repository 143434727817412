import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Login from '../pages/login';
import Register from '../pages/register'

export default withRouter(({ match }) => (
  <>
    <Route path={`${match.path}/login`} component={Login} />
    <Route path={`${match.path}/register`} component={Register} />
  </>
));