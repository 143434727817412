import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import { getAccessToken } from './auth'

export const request = axios.create({
    baseURL: "http://localhost:7777/",
});
  
export const apiWithoutAuth = axios.create({
    baseURL: "http://localhost:7777/",
});

export const api = async (endpoint:string, param:any) =>{
    const token = getAccessToken()
    try {
        const res = (
          await request(endpoint, {
            data: param,
            method:"POST",
            headers: {
                Authorization: `${token}`,
            },
          })
        ).data
        return res
      }
      catch (error) {
        throw error
      }
}